import React from "react";
import "./AchatMedia.scss";
import Modal from "react-bootstrap/Modal";
import PaiementStripeForm from "./PaiementStripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Storage, api } from "@mitim/react-mitim";
import { cle_stripe_achat_cocebal } from "../Constants";

export class AchatMedia extends React.Component {
	state = {
		showModal: false,
		paymentSuccess: false,
		messageModal: "",
		idIntentStripe: "",
		media: {}
	};

	componentDidMount() {
		this.loadPaymentStripe();
	}

	loadPaymentStripe = () => {
		const user = Storage.getUserParsed();
		let token = "";

		token = user.token;
		let achatMedia = {
			user: user.id,
			media: parseInt(this.props.match.params.idMedia)
		};
		const defaultHeaders = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Basic " + token
		};
		if (user) {
			fetch(`${api.timediaUrl}/public/tokenAchatMedia`, {
				method: "POST",
				headers: defaultHeaders,
				body: JSON.stringify(achatMedia)
			})
				.then(res => {
					return res.json();
				})
				.then(data => {
					console.log(data.data);
					if (data.data.paymentSuccess) {
						this.setState({
							messageModal: (
								<>
									Vous avez déjà acheté cet album !<br />
									Merci
								</>
							),
							showModal: true,
							paymentSuccess: true,
							media: data.data.media
						});
					} else {
						this.setState({
							idIntentStripe: data.data.idIntentClientSecretStripe,
							media: data.data.media
						});
					}
				});
		}
	};

	gotToMedia = () => {
		this.setState({ showModal: false }, () =>
			this.state.paymentSuccess
				? this.props.history.push({
						pathname: "/media/" + this.state.media.id,
						state: { from: "/" }
				  })
				: window.location.reload()
		);
	};

	render() {
		const BoiteDialogue = () => (
			<Modal show={this.state.showModal} onHide={this.gotToMedia}>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={this.gotToMedia}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 className="text-center">{this.state.messageModal}</h4>
				</Modal.Body>
			</Modal>
		);
		return (
			<div className="">
				<BoiteDialogue />
				<div className="container-fluid blocTarifs no-gutters">
					<div className="container">
						<h2 className="pb-4 pt-5 text-center">
							Achat en téléchargement : {this.state.media.titre}
						</h2>
						<h3 className="text-center pb-5">
							Montant à payer :{" "}
							{this.state.media.mediaPayant && this.state.media.prix}€
						</h3>
						<div className="achatMedia">
							<div className="paiementCarte" style={{}}>
								<Elements
									stripe={loadStripe(cle_stripe_achat_cocebal)}
									//stripe={loadStripe(cle_stripe_achat_test)}
								>
									<PaiementStripeForm
										self={this}
										idIntentStripe={this.state.idIntentStripe}
									/>
								</Elements>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
