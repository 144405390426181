// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
// Components
import Media from "./Media";
import Header from "../Header/Header";
import "../../styles/Media.scss";
import "../../styles/MediaAlbumCd.scss";
import { tools, api, Icones } from "@mitim/react-mitim";
import "./MediaRecueil.scss";

class MediaRecueil extends Media {
	state = {
		data: {},
		media: {},
		auteurs: [],
		fichiers: [],
		livreBibles: [],
		mediaMedias: [],
		videoYoutubes: [],
		cantique: {},
		cantiqueListen: "",
		id: "0",
		indexLecture: "0",
		classementMediaMedia: "0",
		fetchReceive: false,
		audioListen: "",
		play: false,
		// couleurPlayAll: "#f0f"
		couleurPlayAll: "#87bc3d",
		maxCaractereDescription: 60,
		troisPetitsPoints: "",
		couleur: "#87bc3d"
	};

	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
		window.addEventListener("resize", this.updateWindowDimensions());

		let audioFichier = this.props.app.state.fichiers.filter(
			fichier =>
				fichier.fichier &&
				fichier.fichier.fichierContentType.fichierType.id === 4
		);
		if (audioFichier.length >= 1) {
			this.setState({
				audioListen: audioFichier[0].fichier.sharedLink,
				id: audioFichier[0].fichier.id,
				rangMediaMedia: audioFichier[0].rang,
				fichiers: audioFichier
			});
		}
	}

	getImageSrcByRang(rang) {
		var result = "";
		this.props.app.state.fichiers.forEach(function(element) {
			if (
				element.rang === rang &&
				element.fichier.thumbnail_std &&
				element.couverture
			) {
				result = element.fichier.thumbnail_high.publicLink;
			}
		});

		if (result === "") {
			this.props.app.state.fichiers.forEach(function(element) {
				if (
					element.rang === 1 &&
					element.fichier.thumbnail_std &&
					element.couverture
				) {
					result = element.fichier.thumbnail_high.publicLink;
				}
			});
		}
		return result;
	}

	isContainDownloadFiles() {
		var val = false;
		if (this.props.app.state.fichiers.length > 0) {
			this.props.app.state.fichiers.forEach(function(element) {
				if (!element.couverture || element.couverture == null) {
					val = true;
				}
			});
		}
		return val;
	}
	isContainAudioFiles() {
		var val = false;
		if (this.props.app.state.fichiers.length > 0) {
			this.props.app.state.fichiers.forEach(function(element) {
				if (
					(!element.couverture || element.couverture == null) &&
					element.fichier.extension === "mp3"
				) {
					val = true;
				}
			});
		}
		return val;
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateWindowDimensions() {
		if (window.innerWidth < 1200) {
			this.setState({
				maxCaractereDescription: 28,
				troisPetitsPoints: "..."
			});
		}
		if (window.innerWidth > 700 && window.innerWidth < 1200) {
			this.setState({
				maxCaractereDescription: 68,
				troisPetitsPoints: "..."
			});
		}
	}

	loadMedia() {
		api.getMedia(this.props.idMedia + "?order=nom", this, () => {
			// if (this.state.media.mediaType.id === 2) {

			let audioFichier = this.state.fichiers.filter(
				fichier =>
					fichier.fichier &&
					fichier.fichier.fichierContentType.fichierType.id === 4
			);
			if (audioFichier.length >= 1) {
				this.setState({
					// audioListen:
					// 	"https://storage.timothee.fr/ftp/data/audio/" +
					// 	audioFichier[0].fichier.sha1Sum +
					// 	"." +
					// 	audioFichier[0].fichier.extension,
					audioListen: audioFichier[0].fichier.sharedLink,
					id: audioFichier[0].fichier.id,
					rangMediaMedia: audioFichier[0].rang
				});
			}
			// }
		});
	}

	handleClick = (assMediaFichier, index) => {
		if (assMediaFichier.fichier) {
			console.log("this is:", assMediaFichier.fichier.sharedLink);
			console.log("this is:", assMediaFichier.fichier.id);
			this.setState(
				{
					audioListen: assMediaFichier.fichier.sharedLink,
					id: assMediaFichier.fichier.id,
					indexLecture: index,
					play: true
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
		}
	};

	playAll = () => {
		for (const [index, value] of this.state.fichiers
			.filter(fichier => fichier.fichier)
			.entries()) {
			this.setState(
				{
					audioListen: value.fichier.sharedLink,
					id: value.fichier.id,
					indexLecture: 0,
					play: true
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
			break;
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.state.fichiers
			.filter(fichier => fichier.fichier)
			.entries()) {
			if (this.state.indexLecture < index) {
				this.setState(
					{
						audioListen: value.fichier.sharedLink,
						id: value.fichier.id,
						indexLecture: index,
						play: true
					},
					() => {
						this.audioPlayer.current.type = "audio/mp3";
						this.audioPlayer.current.play();
					}
				);
				break;
			}
		}
	};

	handlePlaying = () => {
		this.setState({ play: true });
	};

	audio() {
		return (
			<>
				<audio
					style={{}}
					src={this.state.audioListen}
					preload="true"
					ref={this.audioPlayer}
					id="audio1"
					controls="controls"
					controlsList="nodownload"
					onEnded={this.handleEnded}
					type="audio/mpeg"
					onPlaying={this.handlePlaying}
				>
					Your browser does not support HTML5 Audio!
				</audio>
			</>
		);
	}

	classOfCantique(assMediaFichier) {
		var classValue = "";
		if (assMediaFichier.fichier) {
			if (assMediaFichier.fichier.id === this.state.id && this.state.play) {
				classValue = "ml-5 my-3 enLecture";
			} else {
				classValue = "ml-5 my-3";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	troisPetitsPoints = texte => {
		if (texte.length > 28) {
			return this.state.troisPetitsPoints;
		} else {
			return "";
		}
	};

	render() {
		const { t } = this.props;
		const titre = this.props.app.state.media.titre;
		const sousTitre = this.props.app.state.media.sousTitre;
		const descriptionMeta = this.props.app.state.metaTagKeyword;
		return (
			<>
				<Header
					slide={{
						template: 2,
						image: "cdj-home.jpg",
						h1: titre,
						h2: sousTitre,
						descriptionMeta: descriptionMeta
					}}
				/>
				<div className="Media">
					{/* <h3 className="container text-center title">
						{this.props.app.state.media.titre}
						<br />
					</h3>
					<h3 className="container text-center">
						{this.props.app.state.media.sousTitre}
					</h3> */}

					{tools.titreSousTitre(titre, sousTitre)}

					<div
						className="container-fluid bg-grey-light mt-5"
						style={{ minHeight: "480px" }}
					>
						<div className="container pl-0 pr-0 pb-5">
							<div className="col-lg-12 col-md-12">
								<div className="row">
									<div className="block col-md-4 col-sm-12 mt-3 mb-3">
										<div className="pt-5 mt-2">
											<img
												src={this.getImageSrcByRang(1)}
												className="img-fluid w-100"
												alt=""
											/>
										</div>
									</div>
									<div className="block col-md-8 col-sm-12 mt-3 mb-3">
										<div className="mt-5">
											<div
												dangerouslySetInnerHTML={{
													__html: this.props.app.state.media.contenu
												}}
											/>
											<div style={{ color: "#bbbdc0" }}>
												{this.props.app.state.media.nbrPages > 0 && (
													<>
														nbr pages : {this.props.app.state.media.nbrPages},{" "}
													</>
												)}
												{this.props.app.state.media.dateEdition && (
													<>
														édition : {this.props.app.state.media.dateEdition},{" "}
													</>
												)}
												{this.props.app.state.media.nbrFichiers && (
													<>{this.props.app.state.media.nbrFichiers} chants, </>
												)}
												{this.props.app.state.media.isbn && (
													<>isbn: {this.props.app.state.media.isbn}, </>
												)}
												{this.props.app.state.media.prix > 0 && (
													<>prix: {this.props.app.state.media.prix}€</>
												)}
												{this.props.app.state.media.lienLjc !== "" && (
													<>
														,{" "}
														<a
															href={
																"https://www.librairiejeancalvin.fr/index.php/ljc/Publications/(id)/" +
																this.props.app.state.media.lienLjc
															}
															target="_blank"
															style={{ color: "#bbbdc0" }}
														>
															Commander
														</a>
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid">
						<div className="container home pl-0 pr-0">
							<div className="row">
								{this.isContainDownloadFiles() && (
									<div className="col-lg-6 pt-5 mb-5">
										<h4 className="title mb-3">Fichier(s) à télécharger</h4>
										<ul className="p-1 p-md-4" style={{ minWidth: "300px" }}>
											{this.props.app.state.fichiers.map(
												fichier =>
													!fichier.couverture && (
														<li key={fichier.fichier.id} className="my-1">
															<a
																style={{ color: "#666" }}
																href={
																	fichier.fichier.link && fichier.fichier.link
																}
															>
																{fichier.fichier.extension}
																{fichier.fichier.description &&
																	" - " + fichier.fichier.description}
															</a>
														</li>
													)
											)}
										</ul>
									</div>
								)}
								{this.isContainAudioFiles() && (
									<div className="col-lg-6 pt-3 mb-5 mediaRecueil">
										<h4 className="title mb-3 pt-4">Audio(s) à écouter</h4>

										<div
											className="text-center pt-4"
											onClick={this.playAll}
											style={{}}
										>
											<span
												className="playAll cursor-pointer"
												onMouseEnter={() => {
													this.setState({ couleurPlayAll: tools.ROUGE });
												}}
												onMouseLeave={() => {
													this.setState({
														couleurPlayAll: this.state.couleur
													});
												}}
											>
												<Icones.play couleur={this.state.couleurPlayAll} />
											</span>
											<span style={{}}>
												<h6
													className="d-inline pl-3"
													style={{ fontSize: "1.2em" }}
												>
													{this.props.texteEcouter ||
														"Écouter l'ensemble des audios"}
												</h6>
											</span>
										</div>

										<div className="text-center pt-2">{this.audio()}</div>

										<table className="table table-condensed mt-5">
											<tbody>
												{this.props.app.state.fichiers
													.filter(
														u =>
															!u.couverture &&
															u.fichier.extension === "mp3" &&
															u.visiblePublic
													)
													.map((assMediaFichier, index) => (
														<tr
															key={assMediaFichier.fichier.id}
															className={
																this.classOfCantique(assMediaFichier) +
																" cursor-pointer"
															}
															onClick={this.handleClick.bind(
																this,
																assMediaFichier,
																index
															)}
														>
															<td>
																<figure style={{ position: "relative" }}>
																	<span
																		className="play2"
																		style={{
																			position: "absolute",
																			marginTop: "-12px"
																		}}
																	>
																		<Icones.play2
																			couleur={this.state.couleur}
																		/>
																	</span>
																	<span className="sound">
																		<img
																			src="https://www.missiontimothee.fr/static/media/sound.0128c6e4.gif"
																			width={30}
																		/>
																	</span>

																	<span
																		style={{
																			backgroundColor: this.state.couleur,
																			position: "absolute",
																			marginTop: "-4px",
																			paddingLeft: "10px",
																			paddingRight: "10px"
																		}}
																		className="text-white ft-lato-regular texteDesc"
																	>
																		{assMediaFichier.fichier.description.substring(
																			0,
																			this.state.maxCaractereDescription
																		) +
																			this.troisPetitsPoints(
																				assMediaFichier.fichier.description
																			)}
																	</span>
																</figure>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediaRecueil);
