// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
// Components
import Header from "../Header/Header";
// Images
import play1 from "../../resources/albumCd/play.svg";
// CSS
import "../../styles/Media.scss";
import "../../styles/MediaAlbumCd.scss";
import { tools } from "@mitim/react-mitim";
import { Bouton } from "./Bouton";
import Modal from "react-bootstrap/Modal";
import "./MediaAlbumCd.scss";

class MediaAlbumCd extends React.Component {
	constructor(props) {
		super(props);
		this.audioPlayer = React.createRef();
	}

	state = {
		showModal: false,
		messageModal: <></>,
		lienVideo: ""
	};

	handleClick = mediaMedia => {
		if (mediaMedia.fichier) {
			console.log("this is:", mediaMedia.fichier.sharedLink);
			console.log("this is:", mediaMedia.fichier.id);
			this.props.app.setState(
				{
					// cantiqueListen:
					// 	"https://storage.timothee.fr/ftp/data/audio/" +
					// 	mediaMedia.fichier.sha1Sum +
					// 	"." +
					// 	mediaMedia.fichier.extension,
					cantiqueListen: mediaMedia.fichier.sharedLink,
					id: mediaMedia.fichier.id,
					rangMediaMedia: mediaMedia.rang,
					classementMediaMedia: mediaMedia.classement
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
		}
	};

	playAll = () => {
		for (const [index, value] of this.props.app.state.mediaMedias
			.filter(mediaMedia => mediaMedia.fichier)
			.entries()) {
			this.props.app.setState(
				{
					cantiqueListen: value.fichier.sharedLink,
					id: value.fichier.id,
					rangMediaMedia: value.rang,
					classementMediaMedia: value.classement
				},
				() => {
					this.audioPlayer.current.type = "audio/mp3";
					this.audioPlayer.current.play();
				}
			);
			break;
		}
	};

	handleEnded = () => {
		for (const [index, value] of this.props.app.state.mediaMedias
			.filter(mediaMedia => mediaMedia.fichier)
			.entries()) {
			if (
				this.props.app.state.classementMediaMedia < value.classement ||
				(this.props.app.state.classementMediaMedia === value.classement &&
					this.props.app.state.rangMediaMedia < value.rang)
			) {
				this.props.app.setState(
					{
						cantiqueListen: value.fichier.sharedLink,
						id: value.fichier.id,
						rangMediaMedia: value.rang,
						classementMediaMedia: value.classement
					},
					() => {
						this.audioPlayer.current.type = "audio/mp3";
						this.audioPlayer.current.play();
					}
				);
				break;
			}
		}
	};

	audio() {
		return (
			<>
				<audio
					style={{}}
					src={this.props.app.state.cantiqueListen}
					preload="true"
					ref={this.audioPlayer}
					id="audio1"
					controls="controls"
					controlsList="nodownload"
					onEnded={this.handleEnded}
					type="audio/mpeg"
				>
					Your browser does not support HTML5 Audio!
				</audio>
			</>
		);
	}

	classOfCantique(mediaMedia) {
		var classValue = "";
		if (mediaMedia.fichier) {
			if (mediaMedia.fichier.id === this.props.app.state.id) {
				classValue = "ml-5 my-3 ligne lineSelected";
			} else {
				classValue = "ml-5 my-3 ligne";
			}
		} else {
			classValue = "ml-5 my-3";
		}
		return classValue;
	}

	getImageSrcByRang(rang) {
		var result = "";
		this.props.app.state.fichiers.forEach(function(element) {
			if (
				element.rang === rang &&
				// element.fichier.thumbnail_std &&
				element.couverture
			) {
				if (element.fichier.thumbnail_high) {
					result = element.fichier.thumbnail_high.sharedLink;
				} else {
					result = element.fichier.thumbnail_std.sharedLink;
				}
			}
		});

		if (result === "") {
			this.props.app.state.fichiers.forEach(function(element) {
				if (
					element.rang === 1 &&
					// element.fichier.thumbnail_std &&
					element.couverture
				) {
					if (element.fichier.thumbnail_high) {
						result = element.fichier.thumbnail_high.sharedLink;
					} else {
						result = element.fichier.thumbnail_std.sharedLink;
					}
				}
			});
		}
		return result;
	}

	isContientFichiers() {
		let contientFichiers = false;
		this.props.app.state.fichiers.map(fichier => {
			if (fichier.fichier && !fichier.couverture) {
				contientFichiers = true;
			}
		});
		return contientFichiers;
	}

	render() {
		const titre = this.props.app.state.media.titre;
		const sousTitre = this.props.app.state.media.sousTitre;
		const descriptionMeta = this.props.app.state.metaTagKeyword;

		const BoiteDialogue = () => (
			<Modal
				className="modalAlbumCd"
				show={this.state.showModal}
				onHide={() => this.setState({ showModal: false })}
				tabindex="-1"
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.setState({ showModal: false })}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{/* <h4 className="text-center">{this.state.messageModal}</h4> */}

					<div class="embed-responsive embed-responsive-16by9">
						<iframe
							className="embed-responsive-item"
							src={this.state.lienVideo}
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen={true}
						></iframe>
					</div>
				</Modal.Body>
			</Modal>
		);

		return (
			<>
				<Header
					slide={{
						template: 2,
						image: "sureau.jpg",
						h1: titre,
						h2: sousTitre,
						descriptionMeta: descriptionMeta
					}}
				/>
				<BoiteDialogue />
				<div className="Media">
					{/* <h3 className="container text-center title">
						{this.props.app.state.media.titre}
						<br />
					</h3>
					<h3 className="container text-center">
						{this.props.app.state.media.sousTitre}
					</h3> */}

					{tools.titreSousTitre(titre, sousTitre)}

					<div
						className="container-fluid bg-grey-light mt-5 no-gutters"
						style={{ minHeight: "480px" }}
					>
						<div className="container pl-0 pr-0">
							<div className="col-lg-12 col-md-12">
								<div className="row">
									<div className="block col-lg-6 col-sm-12 mt-3 mb-3">
										<div className="pt-5 mt-2">
											<img
												src={this.getImageSrcByRang(1)}
												className="img-fluid w-100"
												alt=""
											/>
										</div>
									</div>
									<div className="block col-lg-6 col-sm-12 mt-3">
										<div className="mt-5">
											<div
												dangerouslySetInnerHTML={{
													__html: this.props.app.state.media.contenu
												}}
											/>
											{/* <div style={{ color: "#666" }}> */}
											<div style={{}}>
												<div style={{ fontSize: "1.2em" }}>
													{/* <div style={{ color: "rgb(184, 184, 184)" }}> */}
													{this.props.app.state.media.dureeTotale && (
														<>
															Durée totale :{" "}
															{this.props.app.state.media.dureeTotale}
														</>
													)}
													{this.props.app.state.media.dateEdition && (
														<>, {this.props.app.state.media.dateEdition}</>
													)}
													{this.props.app.state.media.nbrFichiers && (
														<>
															, {this.props.app.state.media.nbrFichiers} chants
														</>
													)}
													{this.props.app.state.media.isbn && (
														<>, {"isbn: " + this.props.app.state.media.isbn}</>
													)}
													{this.props.app.state.media.prix > 0 && (
														<>, {"prix: " + this.props.app.state.media.prix}€</>
													)}
													{this.props.app.state.media.lienLjc !== "" && (
														<>
															,{" "}
															<a
																href={
																	"https://www.librairiejeancalvin.fr/index.php/ljc/Publications/(id)/" +
																	this.props.app.state.media.lienLjc
																}
																target="_blank"
																rel="noopener noreferrer"
																style={{ color: "#bbbdc0" }}
															>
																Acheter le CD
															</a>
														</>
													)}
												</div>

												{this.props.app.state.media.mediaPayant &&
													!this.props.app.state.mediaPurchased && (
														<div className="text-center">
															<div className="pt-5 " style={{ color: "fff" }}>
																<Bouton
																	texte="ACHETER EN TÉLÉCHARGEMENT"
																	lien={
																		"/achat-media/" +
																		this.props.app.state.media.id
																	}
																	couleur={"#009d96"}
																/>
																<br />
																<br />
																<div className="pt-3">
																	l'écoute complète en streaming de l'album sur
																	cette page est possible après achat
																</div>
															</div>
														</div>
													)}
												{this.props.app.state.media.mediaPayant && (
													<>
														<h6 className="pt-3">
															Vidéos d'aide après l'achat du cd en
															téléchargement
														</h6>
														<ul>
															<li>
																<div
																	className="boutonA"
																	onClick={() =>
																		this.setState({
																			showModal: true,
																			lienVideo:
																				"https://www.youtube.com/embed/Fs4VmSPO_pM"
																		})
																	}
																>
																	Comment mettre l'album dans une clé usb pour
																	une écoute dans une voiture
																</div>
															</li>
														</ul>
													</>
												)}

												{this.isContientFichiers() && (
													<h6 className="pt-3">Fichiers à télécharger</h6>
												)}

												<ul className="">
													{this.props.app.state.fichiers.map(
														fichier =>
															fichier.fichier &&
															!fichier.couverture && (
																<li key={fichier.rang} className="">
																	<a
																		href={
																			fichier.fichier.sharedLink &&
																			fichier.fichier.sharedLink
																		}
																		target="_blank"
																		rel="noopener noreferrer"
																	>
																		{fichier.fichier.description &&
																			fichier.fichier.description}
																	</a>
																</li>
															)
													)}
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="row align-self-center">
									<div className="col-12 align-self-center my-4"></div>
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid" style={{ minHeight: "480px" }}>
						<div className="container home pl-0 pr-0">
							<div className="col-lg-12 col-md-12 pl-0 pr-0">
								<div className="container">
									<div className="row align-self-center">
										<div
											className="col-12 align-self-center mt-5 mb-4 cursor-pointer"
											onClick={this.playAll}
										>
											<img src={play1} style={{ width: "40px" }} alt="" />{" "}
											Écouter l’album entier / Écouter des extraits de l’album
										</div>
									</div>
									<div className="row align-self-center" />
									<div className="row">
										<div className="col-md-6 col-sm-12">
											<div className="row">
												<div className="col-sm-12 pt-3">
													<img
														src={this.getImageSrcByRang(2)}
														className="img-fluid w-100"
														alt=""
													/>
												</div>
												<div className="col-sm-12 mt-5 text-center pl-0">
													{this.audio()}
												</div>
											</div>
										</div>
										<div className="block col-md-6 col-sm-12 mt-3 mb-3">
											<table className="table table-condensed player">
												<tbody>
													{this.props.app.state.mediaMedias.map(mediaMedia => (
														<tr
															key={mediaMedia.mediaChild.id}
															className={this.classOfCantique(mediaMedia)}
															onClick={this.handleClick.bind(this, mediaMedia)}
														>
															<td className="player rang">
																<span
																	style={{ color: "#666" }}
																	className="pl-md-4 pr-md-3"
																>
																	{mediaMedia.rang}{" "}
																</span>
															</td>
															<td>{mediaMedia.mediaChild.titre}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(MediaAlbumCd);
